
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


























































































































































































































































































.tv-rack {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: -$spacing 0 $spacing * 3;
  color: $white;

  @include mq(l) {
    margin: 0;
    padding: $spacing * 6 0;

    &:nth-child(2n + 1) {
      z-index: 2;
      background: rgba($white, 0.02);
      box-shadow: $box-shadow-lvl-0;

      &::after {
        @include center-xy;

        content: '';
        z-index: 1;
        left: 0;
        width: 50%;
        height: 50%;
        background: $c-orange;
        mix-blend-mode: normal;
        opacity: 0.1;
        filter: blur(364px);
      }
    }
  }
}

.tv-rack-inner {
  --title-col-width: 40rem;

  @include mq($from: l) {
    display: grid;

    // prettier-ignore
    grid-template: 1fr 1fr / var(--title-col-width, 1fr) calc(100% - var(--title-col-width, 1fr));
    grid-template-areas:
      'title carousel'
      'cta carousel';
    gap: 0 0;
    grid-auto-flow: row;

    .tv-rack__title {
      grid-area: title;
    }

    .tv-rack__carousel {
      grid-area: carousel;
    }

    .tv-rack__cta {
      grid-area: cta;
    }
  }

  @include mq($from: xl) {
    --title-col-width: 50rem;
  }
}

.tv-rack__title {
  @extend %fw-medium;

  margin-bottom: 0;
  color: $white;

  @include mq($until: l) {
    ::v-deep .visual-linebreak::before {
      content: none;
    }
  }

  @include mq(l) {
    position: relative;
    z-index: 2;
    width: var(--title-width);
    min-height: calc(calc(1em * 1.17) * 2); // two line-heights
    padding-right: $spacing * 2;

    .has-subtitle & {
      margin-top: 0;
    }
  }
}

.tv-rack__title__label {
  margin-bottom: $spacing * 0.5;

  @include mq(l) {
    margin-bottom: $spacing * 1.6;
  }
}

.tv-rack__title__subtitle {
  font-family: $ff-alt;

  @include mq(l) {
    order: -1;
    margin-bottom: $spacing * 0.75;
    font-size: 2.4rem;
    font-weight: 500;
  }
}

.tv-rack__carousel {
  margin-top: 2.4rem;

  ::v-deep {
    .carousel {
      --scrollbar-height: 0.3rem;
      --scrollbar-border-radius: 0.3rem;
      --scrollbar-foreground-color: #{$c-pink-medium};
      --scrollbar-background-color: rgb(124 150 164 / 50%);
      --prevnext-button-background: transparent;
      --prevnext-button-border: 2px #{$white} solid;
      --prevnext-color: #{$white};

      width: 100%;
    }

    .carousel__scroller {
      padding-block-end: 3.5rem;

      /* stylelint-disable-next-line declaration-colon-newline-after */ // prettier-ignore
      mask-image: linear-gradient(
        to right,
        rgb(255 255 255 / 000%) 0%,
        rgb(255 255 255 / 100%) var(--scroller-inline-padding-start),
        rgb(255 255 255 / 100%) calc(100% - var(--scroller-inline-padding-end)),
        rgb(255 255 255 / 000%) 100%
      );

      @include mq(l) {
        padding-block-end: 6.6rem;

        /* stylelint-disable-next-line declaration-colon-newline-after */ // prettier-ignore
        mask-image: linear-gradient(
          to right,
          rgb(255 255 255 / 000%) 0%,
          rgb(255 255 255 / 001%) calc(var(--scroller-inline-padding-start) * 0.5),
          rgb(255 255 255 / 010%) calc(var(--scroller-inline-padding-start) * 0.75),
          rgb(255 255 255 / 100%) var(--scroller-inline-padding-start),
          rgb(255 255 255 / 100%) calc(100% - var(--scroller-inline-padding-end)),
          rgb(255 255 255 / 000%) 100%
        );
      }
    }

    .carousel__item {
      position: relative;
    }
  }

  @include mq($from: l) {
    margin-top: 0;
  }
}

.tv-rack__item__card {
  width: 26.2rem;
  height: 100%;
  box-shadow: $box-shadow-lvl-0;

  ::v-deep .card__thumbnail-container {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;

    img {
      @include image-fit;
    }
  }

  ::v-deep .card__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .vertical & {
    width: 15.5rem;

    ::v-deep .card__thumbnail-container {
      padding-bottom: 130%;
    }
  }

  .promo & {
    ::v-deep .card__label {
      background: $c-orange;
    }
  }

  @include mq(l) {
    width: 44rem;
    height: 33.5rem;

    .vertical & {
      width: 21rem;
    }
  }
}

.tv-rack__item__button {
  @extend %button-nostyle;
  @include get-all-space;

  &:focus-visible {
    outline: 2px solid $c-blue-pale;
    outline-offset: 5px;
  }
}

.tv-rack__link {
  display: flex;
  width: fit-content;
  margin: $spacing auto 0;

  @include mq(l) {
    position: relative;
    z-index: 2;
    margin: $spacing * 1.5 0 $spacing * 3.3 0;
  }
}

.tv-rack__equalizer {
  @include mq(l) {
    height: 15rem;
  }
}
