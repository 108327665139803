
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





















































































.tv-universe {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  background: $c-promo-card;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
  }

  &::before {
    background: radial-gradient(
      70.77% 37.46% at 121.79% 106.52%,
      rgba(12, 186, 186, 0.42) 0%,
      rgba(12, 186, 186, 0.14) 49.3%,
      rgba(12, 186, 186, 0) 100%
    );
  }

  &::after {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      radial-gradient(
        143.46% 103.5% at -2.56% 30.21%,
        rgba(12, 186, 186, 0.42) 0%,
        rgba(12, 186, 186, 0.14) 49.3%,
        rgba(12, 186, 186, 0) 100%
      );
  }
}

.tv-rack .tv-news {
  padding-top: $spacing * 6;

  @include mq($until: l) {
    padding-top: $spacing * 5;

    ::v-deep .tv-news-wrapper {
      padding-bottom: 0 !important; // stylelint-disable-line declaration-no-important
    }
  }
}

.tv-rack:nth-child(2n) {
  padding-top: $spacing * 5;

  @include mq(l) {
    padding-top: $spacing * 12;
  }
}

.conditions-container {
  position: relative;
  z-index: 1;
}

.margin-adjust {
  @include mq($until: l) {
    margin-bottom: 2rem;
  }
}
