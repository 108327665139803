
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





















































// See tv/slidy.scss for additional styles

.tv-slider-controls,
[class*='tv-slider-controls--'] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
