
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




























































































































































































































































































.tv-must-see {
  // prettier-ignore
  @include fluid(padding-top, (s: 20px,xl: 110px));

  // prettier-ignore
  @include fluid(padding-bottom, (s: 20px,xl: 110px));

  position: relative;
  z-index: 2;
  overflow: hidden;
  color: $white;
}

.tv-must-see-inner {
  @include mq(m) {
    --slider-width: 55%;
    --slider-gap: #{$spacing * 2};

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 46.7rem;
    padding-left: calc(var(--slider-width) + var(--slider-gap));
  }

  @include mq(xl) {
    --slider-width: 72rem;
  }

  @include mq(xxl) {
    --slider-width: 82rem;
    --slider-gap: #{$spacing * 4};
  }
}

.tv-must-see__link {
  &:focus-visible {
    outline: $c-blue-pale 2px solid;
    outline-offset: 5px;
  }

  @include mq($until: m) {
    display: none;
  }
}

.tv-must-see__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing;

  .tv-slider-controls {
    display: none;
  }

  @include mq(m) {
    justify-content: flex-start;
    margin-top: $spacing * 4;

    .tv-slider-controls {
      display: flex;
    }
  }
}

.tv-must-see__slider {
  @include mq(m) {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--slider-width);
    height: 100%;
  }
}

.must-see-desktop {
  display: none;

  @include mq($from: m) {
    display: block;
  }
}

.must-see-mobile {
  @include mq($from: m) {
    display: none;
  }
}
