
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        


















































































































/* stylelint-disable declaration-no-important */
.tv-default-slider {
  --slide-spacing: #{$spacing * 0.5};

  @media (pointer: fine), (pointer: none) {
    &:focus-within {
      outline: 2px solid $c-blue-pale;
      outline-offset: 2px;
    }
  }
}

.tv-default-slider__inner {
  @extend %list-nostyle;

  &::after {
    content: 'flickity';
    display: none;
  }

  &.flickity-enabled {
    @include mq($until: m) {
      [data-slide] {
        width: 100%;
      }

      [data-slide] + [data-slide] {
        margin-left: var(--slide-spacing);
      }
    }

    @include mq(m) {
      [data-slide] {
        left: 0 !important; // disable flickity styles
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.5s;

        &.is-selected {
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
  }
}

::v-deep .flickity-slider {
  @include mq(m) {
    transform: none !important; // disable flickity styles
  }
}

::v-deep .flickity-page-dots {
  margin-top: $spacing;

  @include mq(m) {
    display: none;
  }
}
