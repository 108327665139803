
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        




































































.tv-must-see__item-outer {
  width: calc(100% - calc(var(--slide-spacing) * 2));
  height: fit-content;

  @include mq(l) {
    width: 100%;
    height: 100%;
  }
}

.tv-must-see__item {
  display: grid;
  grid-template: auto / minmax(auto, 30vw) 1fr;
  grid-gap: $spacing * 0.5 $spacing * 0.7;
  max-width: 90vw;

  @include mq(m) {
    grid-template: 20rem 1fr / 50% 1fr;
    grid-gap: $spacing * 1.2 $spacing;
    height: 100%;
  }

  @include mq(l) {
    grid-template: auto / auto;
    grid-gap: $spacing * 2;
  }

  @include mq(xl) {
    grid-template: max-content / 35rem 1fr;
    grid-gap: $spacing $spacing * 2;
  }
}

.tv-must-see__item__picture {
  position: relative;
  grid-row: 1/2;
  grid-column: 1/2;
  overflow: hidden;
  margin: 0;
  aspect-ratio: 3/4;
  border-radius: 1rem;

  img {
    @include image-fit;
  }

  @include mq(s) {
    height: 100%;
  }

  @include mq(xl) {
    grid-row: 1/3;
    max-width: 35rem;
    height: 100%;
  }
}

.tv-must-see__item__infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  grid-column: 2/3;
  justify-self: start;

  @include mq(xl) {
    justify-content: flex-start;
  }
}

.tv-must-see__item__title {
  margin: 0 0 $spacing * 0.5;
}

.tv-must-see__item__subtitle,
.tv-must-see__expert__title,
.tv-must-see__expert__function {
  @extend %fw-normal;
  @extend %text-uppercase;

  // prettier-ignore
  @include fluid(font-size, (s: 9px,xl: 18px));

  // prettier-ignore
  @include fluid(line-height, (s: 11px,xl: 24px));

  // prettier-ignore
  @include fluid(letter-spacing, (s: 0.6px,xl: 1px));

  opacity: 0.5;
}

.tv-must-see__item__link {
  margin-top: $spacing * 0.8;
  white-space: nowrap;

  &:focus-visible {
    outline: $c-blue-pale 2px solid;
    outline-offset: 5px;
  }

  @include mq($until: s) {
    padding: 1rem 1.5rem !important; // same as regular btn

    ::v-deep {
      .btn__title {
        font-size: 1.5rem !important; // same as regular btn
      }

      .btn__icon {
        margin-left: $spacing * 0.25 !important;
      }
    }
  }

  @include mq(m) {
    display: none;
  }
}

.tv-must-see__expert {
  --expert-picture-width: 2.6rem;

  display: grid;
  grid-template: auto / var(--expert-picture-width) 1fr;
  grid-gap: 0 $spacing * 0.5;
  grid-column: 1/3;
  max-height: 25rem;
  padding: $spacing * 0.5 $spacing * 0.7 $spacing * 0.5 $spacing * 0.5;
  background: rgba($white, 0.02);
  border-radius: 1rem;
  box-shadow: $box-shadow-lvl-1;

  @include mq(m) {
    --expert-picture-width: 4rem;
  }

  @include mq(xl) {
    display: grid;
    grid-template-rows: repeat(2, calc(var(--expert-picture-width) * 0.5)) auto;
    grid-column: 2/3;
    max-height: 32rem;
    padding: $spacing;
  }

  @include mq(xxl) {
    --expert-picture-width: 8rem;

    padding: $spacing * 1.3;
  }
}

.tv-must-see__expert__title {
  margin: 0;
  opacity: 1;

  @include mq(xl) {
    grid-column: 2/3;
    align-self: flex-end;
  }
}

.tv-must-see__expert__function {
  opacity: 0.5;

  @include mq(xl) {
    grid-column: 2/3;
  }
}

.tv-must-see__expert__picture {
  position: relative;
  grid-row: 1/3;
  overflow: hidden;
  width: var(--expert-picture-width);
  height: var(--expert-picture-width);
  margin: 0;
  border-radius: 50%;

  img {
    @include image-fit;
  }
}

.tv-must-see__expert__text {
  // prettier-ignore
  @include fluid(font-size, (s: 12px,l: 18px));

  grid-row: 3/4;
  grid-column: 1/3;
  overflow-y: auto;
  max-height: 13rem;
  margin-top: $spacing * 0.5;
  padding-right: $spacing * 0.5;

  ::v-deep p {
    margin: 0;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: $c-anthracite-light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $c-pink-medium;
  }

  @include mq(m) {
    max-height: none;
  }

  @include mq(xl) {
    margin-top: $spacing;
  }
}
